import React, { useEffect, useState } from "react";
import { supabase, customSort } from "../utils";
import play from "../icons/Subtract.svg";

const Portfolio = () => {
  // const [activeButton, setActiveButton] = useState(null);
  const [portfolioLinks, setPortfolioLinks] = useState([]);
  // const [posterArr, setPosterArr] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);

  async function fetchPosterLink() {
    const { data, error } = await supabase.from("portfolio_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPortfolioLinks(cloneData);

      const arr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "portfolio" &&
          (key.split("_")[1] === "films" ||
            key.split("_")[1] === "img" ||
            key.split("_")[1] === "commercials")
      );
      arr.sort(customSort);
      // setPosterArr(arr);
      setFilteredImages(arr);
    }
  }

  // Call the fetchPosterLink function
  useEffect(() => {
    fetchPosterLink();
  }, []);

  // const handleClick = (button) => {
  //   const filterPoster = posterArr.filter((image) =>
  //     image[0].split("_").includes(button)
  //   );
  //   setFilteredImages(filterPoster);
  //   setActiveButton(button);
  // };

  return (
    <div class="relative bg-black">
      <div class="w-full h-full">
        <img
          src={portfolioLinks.banner_bg_img}
          alt="about_banner"
          class="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {" "}
        {portfolioLinks.banner_title}
      </div>
      <div className="container mx-auto  md:p-16 p-4">
        {/* <div className="flex md:gap-8 gap-4 justify-center md:pb-8">
          <button
            onClick={() => handleClick("img")}
            type="submit"
            className={`text-white md:h-[50px] md:w-[194px] h-[25px] w-[83px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
              activeButton === "img" ? "bg-neon-pink" : "button-gred"
            }`}
          >
            All Works
          </button>
          <button
            onClick={() => handleClick("films")}
            type="submit"
            className={`text-white md:h-[50px] md:w-[99px] h-[25px] w-[59px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
              activeButton === "films" ? "bg-neon-pink" : "button-gred"
            }`}
          >
            Films
          </button>

          <button
            onClick={() => handleClick("commercials")}
            type="submit"
            className={`text-white md:h-[50px] md:w-[194px] h-[25px] w-[107px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
              activeButton === "commercials" ? "bg-neon-pink" : "button-gred"
            }`}
          >
            Commercials
          </button>
        </div> */}
        <div>
          <div className="flex gap-12 flex-wrap pb-36 pt-8">
            {filteredImages.map((link) => {
              return (
                <div className="md:w-[500px] md:h-[600px] w-[398px] h-[500px] mx-auto 2xl:mx-0 relative">
                  <img
                    className="object-cover h-full w-full "
                    src={link[1]}
                    alt="card"
                  />
                  <img
                    className="absolute h-[96px] w-[96px] top-[42%] left-[40%]"
                    src={play}
                    alt="play"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import React, { useEffect, useState } from "react";
import linkedIn from "../icons/skill-icons_linkedin.png";
import brace from "../icons/623c95682a8f369e0a4ca80b_Quotes.svg.png";
import { customSort, supabase } from "../utils";
import ContactForm from "../components/ContactForm";
import leftArrow from "../icons/arrow-left-white.svg";
import rightArrow from "../icons/arrow-right-white.svg";

const About = () => {
  const [aboutPageData, setAboutPageData] = useState({});
  const [lovedImg, setLovedImg] = useState([]);
  const [lovedDes, setLovedDes] = useState([]);
  const [lovedName, setLovedName] = useState([]);
  const [lovedPost, setLovedPost] = useState([]);
  const [selectedLoved, setSelectedLoved] = useState(0);

  async function fetchAboutPageData() {
    const { data, error } = await supabase.from("about_Page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.rsource_value,
        });
      });
      setAboutPageData(cloneData);

      const lovedImgArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "our" &&
          key.split("_")[1] === "team" &&
          key.split("_")[2] === "img"
      );
      lovedImgArr.sort(customSort);
      setLovedImg(lovedImgArr);

      const lovedDesArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "our" &&
          key.split("_")[1] === "team" &&
          key.split("_")[2] === "Info"
      );
      lovedDesArr.sort(customSort);
      setLovedDes(lovedDesArr);

      const lovedNameArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "our" &&
          key.split("_")[1] === "team" &&
          key.split("_")[2] === "name"
      );
      lovedNameArr.sort(customSort);
      setLovedName(lovedNameArr);

      const lovedPostArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "our" &&
          key.split("_")[1] === "team" &&
          key.split("_")[2] === "post"
      );
      lovedPostArr.sort(customSort);
      setLovedPost(lovedPostArr);
      // Update your component state with the fetched data
    }
  }

  const handleSelectedIndex = (index) => {
    setSelectedLoved(index);
  };

  // Call the fetchAboutPageData function when needed, e.g., in a useEffect hook
  useEffect(() => {
    fetchAboutPageData();
  }, []);

  return (
    <div className="relative bg-black">
      <div className="w-full h-full">
        <img
          src={aboutPageData.banner_bg_image}
          alt="banner"
          className="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div className="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {" "}
        {aboutPageData.banner_title}
      </div>

      <div className="container mx-auto  md:p-16 p-4">
        <div className="flex justify-center">
          <div className="text-shadow-neon-pink font-monument-extended font-normal md:text-[56px] text-2xl md:w-[1044px] text-center md:leading-[68px] tracking-normal text-center text-neon-pink pb-8">
            “Transforming Worlds, Unleashing Wonders.”
          </div>
        </div>

        <div className="lg:flex justify-between block gap-4 md:py-16 py-8">
          <div className="relative lg:w-[41%] lg:h-[1398px] h-[701px]">
            <div className=" lg:w-full w-[304px] lg:h-[859px] h-[430px] border-gred-4 md:rounded-2xl">
              <img
                className="object-cover h-full w-full md:rounded-[12px]"
                src={aboutPageData.who_we_are_img_1}
                alt="aboutLab"
              />
            </div>
            <div className="absolute top-[54%] left-[30%] lg:w-[80%] w-[223px] lg:h-[402px] h-[201px] border-gred-4 md:rounded-2xl">
              <img
                className="object-cover h-full w-full md:rounded-[12px]"
                src={aboutPageData.who_we_are_img_2}
                alt="aboutLab2"
              />
            </div>
            <div className="absolute top-[70%] lg:w-[90%] lg:h-[438px] h-[219px] border-gred-4 md:rounded-2xl">
              <img
                className="object-cover h-full w-full md:rounded-[12px]"
                src={aboutPageData.who_we_are_img_3}
                alt="aboutLab3"
              />
            </div>
          </div>
          <div className="text-white lg:w-[50%]">
            <div className="md:my-24 my-12 md:w-full">
              <div className="font-monument-extended md:text-[40px] text-2xl flex items-center font-normal md:leading-[48px] tracking-normal md:my-6">
                <span className="border-gred-2 mr-4 md:w-[80px] w-[40px] inline-block"></span>
                Who We Are
              </div>
              <div className="md:text-2xl text-base font-normal md:ml-12 ml-4 md:leading-7 leading-5 tracking-normal">
                {aboutPageData.who_we_are}
              </div>
            </div>
            <div className="md:my-24 my-12 md:w-full">
              <div className="font-monument-extended md:text-[40px] text-2xl flex items-center font-normal md:leading-[48px] tracking-normal md:my-6">
                <span className="border-gred-2 mr-4 md:w-[80px] w-[40px] inline-block"></span>
                How It Started
              </div>
              <div className="md:text-2xl text-base font-normal md:ml-12 ml-4 md:leading-7 leading-5 tracking-normal">
                {aboutPageData.how_it_started}
              </div>
            </div>
            <div className="md:my-24 my-12 md:w-full">
              <div className="font-monument-extended md:text-[40px] text-2xl flex items-center font-normal md:leading-[48px] tracking-normal md:my-6">
                <span className="border-gred-2 mr-4 md:w-[80px] w-[40px] inline-block"></span>
                What We Believe
              </div>
              <div className="md:text-2xl text-base font-normal md:ml-12 ml-4 md:leading-7 leading-5 tracking-normal">
                {aboutPageData.what_we_believe}
              </div>
            </div>
            <div className="md:my-24 my-12 md:w-full">
              <div className="font-monument-extended md:text-[40px] text-2xl flex items-center font-normal md:leading-[48px] tracking-normal md:my-6">
                <span className="border-gred-2 mr-4 md:w-[80px] w-[40px] inline-block"></span>
                How We Work
              </div>
              <div className="md:text-2xl text-base font-normal md:ml-12 ml-4 md:leading-7 leading-5 tracking-normal">
                {aboutPageData.how_we_work}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center text-center md:py-16 py-8">
          <div className="w-[1412px]">
            <div className="text-shadow-neon-pink md:text-4xl text-xl font-medium md:leading-[44px] leading-6 tracking-normal  ">
              “Our team believes in precision and quality; there are teams for
              each skill. Dividing up work amongst people who do what they do
              the best. Everything comes together marvelously to showcase the
              story you want to tell.”
            </div>
            <div className="font-semibold md:text-[32px] text-base text-white md:leading-10 leading-6">
              says Little Black Stars
            </div>
          </div>
        </div>
        <div className="flex justify-center text-center md:py-16 py-8">
          <div className="w-[1078px]">
            <div className="font-monument-extended text-[#E4A0F7] font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7">
              Our Culture
            </div>
            <div className="font-semibold md:text-[32px] text-base text-white md:leading-10 leading-7">
              {aboutPageData.our_culture}
            </div>
          </div>
        </div>
        <div className="lg:flex block justify-center gap-4 text-white md:py-16 py-8">
          <div className="flex flex-col justify-between lg:w-[30%] w-full items-end text-right">
            <div className="text-neon-pink w-full font-normal md:text-[64px] text-2xl md:leading-[82px] leading-8">
              <div className="font-[Abel] lg:float-left">
                Our
                <br />
                Enjoyment
              </div>
            </div>
            <div className="md:text-2xl text-base font-semibold md:leading-7 leading-5 md:w-[95%]">
              {aboutPageData.our_enjoyment}
            </div>
          </div>
          <div className="lg:w-[65%] w-full lg:my-0 my-8">
            <div className="md:h-[546px] w-full border-gred-4 md:rounded-2xl">
              <img
                className="object-cover h-full w-full md:rounded-[12px]"
                src={aboutPageData.our_enjoyment_img}
                alt="ourEnvironment"
              />
            </div>
          </div>
        </div>
        <div className="lg:flex justify-center items-center md:pt-40 pt-20 md:pb-32 pb-16">
          <div className="lg:w-[80%] md:h-[567px] h-[265px] relative flex">
            <div className="z-10">
              <div className="font-[Abel] text-neon-pink font-normal md:text-[32px] text-lg pb-2">
                High on Caffeine
              </div>
              <div className="xl:w-full lg:w-[60%] md:h-[488px] h-[150px] border-gred-4 md:rounded-2xl">
                <img
                  className="object-cover h-full w-full md:rounded-[12px]"
                  src={aboutPageData.high_on_caffeine_img_1}
                  alt="team1"
                />
              </div>
            </div>
            <div className="absolute left-[41%] top-[-5%]">
              <div className="md:w-full md:h-[488px] h-[160px]  border-gred-4 md:rounded-2xl">
                <img
                  className="object-cover h-full w-full md:rounded-[12px]"
                  src={aboutPageData.high_on_caffeine_img_2}
                  alt="team1"
                />
                <div className="font-[Abel] text-neon-pink font-normal md:text-[32px] lg:mr-8 text-base text-end md:pt-4 leading-7 pt-4 w-[42%] float-end">
                  Always Positive + Tea
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7 text-center text-[#E4A0F7] md:pb-16 pb-8">
            Our Team
          </div>
          {lovedImg.length &&
            lovedImg.map((ele, index) => {
              return (
                <>
                  {selectedLoved === index && (
                    <div className="lg:flex block justify-between text-white lg:h-[961px] h-[1000px] lg:p-20 p-4 bg-gradient-to-b from-transparent via-transparent to-black bg-cyan-300 bg-opacity-50 lg:py-16">
                      <div className="lg:w-[41%] lg:h-[720px] h-[479px]">
                        <img
                          className="object-cover h-full w-full md:rounded-xl black-and-white-image"
                          src={ele[1]}
                          alt="krishna"
                        />
                      </div>
                      <div className="lg:w-[50%] lg:my-0 md:my-8 my-4">
                        <div className="font-monument-extended font-normal md:text-5xl text-2xl leading-8">
                          {lovedName[index][1]}
                        </div>
                        <div className="font-monument-extended font-normal md:text-[40px] text-2xl md:my-4 leading-8">
                          {lovedPost[index][1]}
                        </div>
                        <img src={linkedIn} alt="linkedIn" />
                        <img
                          src={brace}
                          alt="brace"
                          className="lg:mt-40 mt-16"
                        />

                        <div className="font-medium md:text-2xl text-sm md:leading-7 leading-4 md:mt-8 mt-4 md:ml-20">
                          {lovedDes[index][1]}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          <div className="flex justify-center items-center w-full">
            <div className="xl:mb-20 mb-10 2xl:w-[90%] w-[98%] xl:mt-0 mt-20">
              <div className="relative border border-white mb-4">
                <div className="w-[10px] h-[10px] rounded-[50%] bg-white absolute bottom-[-5px] left-[-1px]"></div>
                <div className="w-[10px] h-[10px] rounded-[50%] bg-white absolute bottom-[-5px] right-[-1px]"></div>
              </div>
              <div className="flex justify-between">
                <div className="w-full flex justify-start">
                  {selectedLoved > 0 && (
                    <img
                      className="animate-bounce cursor-pointer"
                      src={leftArrow}
                      alt="leftArrow"
                      onClick={() => handleSelectedIndex(selectedLoved - 1)}
                    />
                  )}
                </div>

                <div className="w-full text-white text-center text-2xl">
                  {selectedLoved + 1}/{lovedImg.length}
                </div>
                <div className="w-full flex justify-end">
                  {selectedLoved < lovedImg.length - 1 && (
                    <img
                      className="animate-bounce cursor-pointer"
                      src={rightArrow}
                      alt="leftArrow"
                      onClick={() => handleSelectedIndex(selectedLoved + 1)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:py-24 py-8">
          <div className="font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7 text-center text-[#E4A0F7]">
            CONTACT US
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import { supabase } from "../utils";
import { Link } from "react-router-dom";

const Footer = () => {
  const [companyDetailsData, setCompanyDetailsData] = useState({});

  async function fetchCompanyDetailsData() {
    const { data, error } = await supabase.from("company_details").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setCompanyDetailsData(cloneData);
      // Update your component state with the fetched data
    }
  }

  useEffect(() => {
    fetchCompanyDetailsData();
  }, []);
  return (
    <div>
      <div className="xl:h-[436px] border-y border-gred bg-black flex flex-col items-center justify-center text-white">
        {" "}
        <img
          className="md:h-[180px] h-[150px] md:w-[400px] w-[300px]"
          src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/lbs_logo/lbs_logo.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJsYnNfbG9nby9sYnNfbG9nby5zdmciLCJpYXQiOjE3MTAzMzM4OTksImV4cCI6MTc5NjY0NzQ5OX0.Pby2wkKZAwdNCAa__NvvNgw2y0rhywelCVN5rBu8SNw&t=2024-03-13T12%3A44%3A59.407Z"
          alt="logo"
        />
        <ul className="xl:flex block text-center gap-8 my-4">
          <Link to="/">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              Home
            </li>
          </Link>
          <Link to="/services">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              Services
            </li>
          </Link>
          <Link to="/portfolio">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              Portfolio
            </li>
          </Link>
          <Link to="/lbsInWeb3">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              LBS-in-Web3
            </li>
          </Link>
          <Link to="/about">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              About{" "}
            </li>
          </Link>
          <Link to="/contact">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              Contact
            </li>
          </Link>
          <Link to="/careers">
            <li className="font-monument-extended font-normal text-2xl leading-[29px] tracking-normal my-2">
              Careers{" "}
            </li>
          </Link>
        </ul>
        <div className="flex sm:gap-8 gap-4 my-6 ">
          <Link to={companyDetailsData.company_insta} target="_blank">
            <img
              className="w-[41px] h-[41px]"
              src={companyDetailsData.insta_img}
              alt="instaLogo"
            />
          </Link>
          <Link to={companyDetailsData.company_youtube} target="_blank">
            <img
              className="w-[41px] h-[41px]"
              src={companyDetailsData.youtube_img}
              alt="youTubeLogo"
            />
          </Link>
          <Link to={companyDetailsData.company_linkedin} target="_blank">
            <img
              className="w-[41px] h-[41px]"
              src={companyDetailsData.linkedin_img}
              alt="linkedinLogo"
            />
          </Link>
          <Link to={companyDetailsData.company_discord} target="_blank">
            <img
              className="w-[41px] h-[41px]"
              src={companyDetailsData.discord_img}
              alt="cordLogo"
            />
          </Link>
          <Link to={companyDetailsData.company_twitter} target="_blank">
            <img
              className="w-[41px] h-[41px]"
              src={companyDetailsData.twitter_img}
              alt="twitLogo"
            />
          </Link>
        </div>
      </div>
      <div className="font-medium md:text-xl text-sm leading-6 tracking-normal text-white md:p-8 p-4 bg-black">
        All rights reserved ® LittleblackstarVFX{" "}
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./font.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import LBSInWeb3 from "./pages/LBSInWeb3";
import Portfolio from "./pages/Portfolio";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import CareersApplication from "./components/CareersApplication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";

ReactGA.initialize("UA-XXXXXXXXX-X");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <div>
        <Navbar />
        <ToastContainer theme="dark" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogDetails/:id" element={<BlogDetails />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/lbsInWeb3" element={<LBSInWeb3 />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/careersapplication" element={<CareersApplication />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

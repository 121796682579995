import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import close from "../icons/Close.png";
import locationIcon from "../icons/Location.png";
import instaLogo from "../icons/instagram.png";
import flickrLogo from "../icons/flickr.png";
import pintLogo from "../icons/pinterest.png";
import twitLogo from "../icons/twitter.png";
import emailLogo from "../icons/Email.svg.png";
import arrowBttom from "../icons/arrow-bottom.svg";
import { supabase } from "../utils";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyDetailsData, setCompanyDetailsData] = useState({});

  async function fetchCompanyDetailsData() {
    const { data, error } = await supabase.from("company_details").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setCompanyDetailsData(cloneData);
      // Update your component state with the fetched data
    }
  }

  useEffect(() => {
    fetchCompanyDetailsData();
  }, []);

  const toggleSlider = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-transparent absolute inset-0 w-full h-[110px] z-10 flex items-center md:top-[10px] justify-between xl:pl-[4.5%] xl:pr-[5%] pr-2 py-8">
      <img
        className="md:h-[100px] md:w-[231px] w-[120px] h-[52px]"
        src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/lbs_logo/lbs_logo.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJsYnNfbG9nby9sYnNfbG9nby5zdmciLCJpYXQiOjE3MTAzMzM4OTksImV4cCI6MTc5NjY0NzQ5OX0.Pby2wkKZAwdNCAa__NvvNgw2y0rhywelCVN5rBu8SNw&t=2024-03-13T12%3A44%3A59.407Z"
        alt="logo"
      />
      <div className="flex">
        <ul className="xl:flex hidden items-center 2xl:gap-12 gap-8 text-white font-semibold 2xl:text-2xl text-xl leading-7">
          <NavContainer />
        </ul>
        <button
          className="toggle-btn flex items-end ml-12"
          onClick={toggleSlider}
        >
          <div className="w-[100%] bar"></div>
          <div className="w-[50%] bar"></div>
          <div className="w-[100%] bar"></div>
          <div className="w-[50%] bar"></div>
        </button>
      </div>

      <div className={`slider ${isOpen ? "open" : ""}`}>
        <div className="p-4 h-full overflow-scroll">
          <div className="text-right p-8 flex justify-end">
            <img
              src={close}
              alt="close"
              className="cursor-pointer"
              onClick={toggleSlider}
            />
          </div>
          <div className="text-center text-white flex flex-col items-center justify-center md:mt-16">
            <img
              className="h-[100px] w-[231px]"
              src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/lbs_logo/lbs_logo.svg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJsYnNfbG9nby9sYnNfbG9nby5zdmciLCJpYXQiOjE3MTAzMzM4OTksImV4cCI6MTc5NjY0NzQ5OX0.Pby2wkKZAwdNCAa__NvvNgw2y0rhywelCVN5rBu8SNw&t=2024-03-13T12%3A44%3A59.407Z"
              alt="logo"
            />
            <div className="font-medium md:text-lg text-sm md:leading-6 md:mt-8 mt-4">
              Welcome to LittleblackstarVFX, where creativity knows no bounds.
              We weave visual magic to bring your wildest imaginations to life
              on screen. Our team of skilled artists crafts captivating visual
              effects that leave a lasting impact on your storytelling.
            </div>
            <div className="w-[80%] border md:my-12 my-4"></div>
            <ul className="xl:hidden block items-center gap-12 text-white font-semibold text-2xl leading-7">
              <NavContainer />
            </ul>
            <div className="xl:block hidden">
              <div className="font-[Abel] ml-12 font-normal text-[20px] leading-[18px] tracking-[1.5px] text-left">
                Contact Details
              </div>
              <div className="font-semibold text-[20px] leading-[26px] tracking-normal flex gap-4 items-center my-6 w-[371px]">
                <img src={locationIcon} alt="location" />
                {companyDetailsData.company_address}
              </div>

              <div className="flex gap-4 font-semibold text-[20px] leading-[18px] tracking-normal">
                <img src={emailLogo} alt="emailLogo" />
                {companyDetailsData.company_mail}
              </div>
            </div>

            <div className="w-[80%] border md:my-6 my-4"></div>
            <div className="font-[Abel] font-normal text-[20px] leading-[18px] tracking-[1.5px]">
              Follow Us
            </div>
            <div className="flex gap-8 my-8 ">
              <Link to={companyDetailsData.company_insta} target="_blank">
                <img src={instaLogo} alt="instaLogo" />
              </Link>
              <Link to={companyDetailsData.company_flickr} target="_blank">
                <img src={flickrLogo} alt="flickrLogo" />
              </Link>
              <Link to={companyDetailsData.company_insta} target="_blank">
                <img src={instaLogo} alt="instaLogo" />
              </Link>
              <Link to={companyDetailsData.company_pinterest} target="_blank">
                <img src={pintLogo} alt="pintLogo" />
              </Link>
              <Link to={companyDetailsData.company_twitter} target="_blank">
                <img src={twitLogo} alt="twitLogo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavItem = ({ to, currentPath, children }) => {
  const isActive = to === currentPath;
  return (
    <>
      <NavLink
        to={to}
        className={`${
          isActive ? "text-neon-pink" : "text-white"
        } hover:text-neon-pink`}
      >
        {children}
      </NavLink>
    </>
  );
};

const NavContainer = () => {
  // State to manage the visibility of the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to handle hover event
  const handleHover = () => {
    setIsDropdownOpen(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  const location = useLocation();
  return (
    <>
      {" "}
      <li>
        <NavItem currentPath={location.pathname} to="/">
          Home
        </NavItem>
      </li>
      <li>
        <NavItem currentPath={location.pathname} to="/services">
          Services
        </NavItem>
      </li>
      <li>
        <NavItem currentPath={location.pathname} to="/portfolio">
          Portfolio
        </NavItem>
      </li>
      <li>
        <NavItem currentPath={location.pathname} to="/lbsInWeb3">
          LBS In Web3
        </NavItem>
      </li>
      <li>
        <div
          className="relative"
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          Company <img className="inline" src={arrowBttom} alt="arrowBttom" />{" "}
          {isDropdownOpen && (
            <div className="absolute right-[3%]">
              <div className="flex flex-col gap-8  bg-purple-green p-6 mt-4 rounded  w-[180px]">
                <NavItem currentPath={location.pathname} to="/about">
                  About
                </NavItem>
                <NavItem currentPath={location.pathname} to="/blog">
                  Blog
                </NavItem>
              </div>
            </div>
          )}
        </div>
      </li>
      <li>
        <NavItem currentPath={location.pathname} to="/contact">
          Contact
        </NavItem>
      </li>
      <li>
        <NavItem currentPath={location.pathname} to="/careers">
          Careers
        </NavItem>
      </li>
      {/* <li>
    <NavItem currentPath={location.pathname} to="/blog">
      Blog
    </NavItem>
  </li>
  <li>
    <NavItem currentPath={location.pathname} to="/blogDetails">
      Blog Details
    </NavItem>
  </li> */}
    </>
  );
};

export default Navbar;

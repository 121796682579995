import React, { useState } from "react";
import careers from "../icons/Group.svg";
import arrowRight from "../icons/arrow-right.png";
import urlLink from "../icons/tabler_link.svg";
import { toast } from "react-toastify";
import { supabase } from "../utils";

const CareersApplication = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    place: "",
    department: "",
    designation: "",
    working_currently: "",
    work_experience: "",
    job_type: "",
    portfolio_url: "",
    resume: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const postFormData = async (event) => {
    event.preventDefault();
    await supabase.from("careers_form").insert(formData);
    setFormData({
      name: "",
      email: "",
      number: "",
      place: "",
      department: "",
      designation: "",
      working_currently: "",
      work_experience: "",
      job_type: "",
      portfolio_url: "",
      resume: "",
      message: "",
    });
    setActiveButton(null);
    toast("Message send successfully");
  };

  const handleClick = (button, value) => {
    setActiveButton(button);
    setFormData((prevData) => {
      return {
        ...prevData,
        job_type: value,
      };
    });
  };
  return (
    <div class="relative bg-black">
      <div className="container mx-auto text-white md:p-16 p-4">
        <div className="md:flex gap-12 pt-[150px]">
          <div className="md:w-[50%] md:h-[779px]">
            <img src={careers} alt="careers" />
          </div>

          <form onSubmit={postFormData} className="md:w-[50%]">
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                What's your name *{" "}
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                required
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Your email ID, Please *
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                required
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Give your contact number *
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                required
                type="number"
                name="number"
                onChange={handleChange}
                value={formData.number}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Where are you from?
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
                name="place"
                onChange={handleChange}
                value={formData.place}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Which department you are applying? *
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
                name="department"
                onChange={handleChange}
                value={formData.department}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                What's your designation *
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
                name="designation"
                onChange={handleChange}
                value={formData.designation}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Are you working currently?
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
                name="working_currently"
                onChange={handleChange}
                value={formData.working_currently}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                How long you been working?
              </div>
              <input
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
                name="work_experience"
                onChange={handleChange}
                value={formData.work_experience}
              />
            </div>
            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                What are you looking for?
              </div>
              <div className="flex md:gap-8 gap-4 justify-start">
                <div
                  onClick={() => handleClick(1, "Full Time")}
                  className={`flex justify-center items-center text-white md:h-[50px] md:w-[194px] h-[25px] w-[83px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
                    activeButton === 1 ? "bg-neon-pink" : "button-gred-1"
                  }`}
                >
                  Full Time
                </div>
                <div
                  onClick={() => handleClick(2, "Intern")}
                  className={`flex justify-center items-center text-white md:h-[50px] md:w-[99px] h-[25px] w-[59px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
                    activeButton === 2 ? "bg-neon-pink" : "button-gred-1"
                  }`}
                >
                  Intern
                </div>

                <div
                  onClick={() => handleClick(3, "Contract")}
                  className={`flex justify-center items-center text-white md:h-[50px] md:w-[194px] h-[25px] w-[107px] rounded md:rounded-lg my-6 font-medium md:text-2xl text-xs md:leading-7 leading-3 ${
                    activeButton === 3 ? "bg-neon-pink" : "button-gred-1"
                  }`}
                >
                  Contract
                </div>
              </div>
            </div>
            <div className="py-8 relative">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Add your portfolio or CV URL here *
              </div>
              <input
                className="w-full p-4 pl-12 bg-black border-gred-b-2 my-4"
                type="text"
                name="portfolio_url"
                onChange={handleChange}
                value={formData.portfolio_url}
              />
              <img
                src={urlLink}
                alt="urlLink"
                className="absolute bottom-[31%] left-[1%]"
              />
            </div>

            <div className="flex md:justify-start justify-center items-center">
              <label
                htmlFor="file"
                className="cursor-pointer inline-block flex justify-center items-center button-gred text-white h-[60px] w-[311px] rounded-xl my-6 font-semibold text-base"
              >
                <input
                  name="resume"
                  onChange={handleChange}
                  value={formData.resume}
                  type="file"
                  id="file"
                  className="hidden"
                />
                Upload Resume
                <img src={arrowRight} alt="arrowRight" className="ml-2" />
              </label>
            </div>

            <div className="py-8">
              <div className="font-semibold md:text-[32px] text-base leading-5 md:leading-10">
                Leave your message
              </div>
              <textarea
                name="message"
                onChange={handleChange}
                value={formData.message}
                rows={6}
                className="w-full p-4 bg-black border-gred-b-2 my-4"
                type="text"
              />
            </div>
            <div className="flex md:justify-end justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center button-gred text-white h-[60px] w-[195px] rounded-xl my-6 font-semibold text-base"
              >
                Submit{" "}
                <img src={arrowRight} alt="arrowRight" className="ml-2" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareersApplication;

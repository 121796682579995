import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import { customSort, supabase } from "../utils";

const Blog = () => {
  const [pageData, setPageData] = useState([]);
  const [blogArr, setBlogArr] = useState([]);

  async function fetchBlogData() {
    const { data, error } = await supabase.from("blog_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPageData(cloneData);

      const arr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "blog" && key.split("_")[1] === "img"
      );
      arr.sort(customSort);
      setBlogArr(arr);
    }
  }

  // Call the fetchBlogData function
  useEffect(() => {
    fetchBlogData();
  }, []);

  return (
    <div class="relative bg-black">
      <div class="w-full h-full">
        <img
          src={pageData.banner_bg_img}
          alt="about_banner"
          class="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {" "}
        {pageData.banner_title}
      </div>
      <div className="container mx-auto  md:p-16 p-4">
        <div className="flex flex-wrap gap-12 py-12">
          {blogArr.length &&
            blogArr.map((ele, index) => {
              return (
                <Link key={index + 1} to={`/blogdetails/${index + 1}`}>
                  <div className="md:w-[360px] md:h-[500px] h-[400px] w-full mx-auto 2xl:mx-0">
                    <img
                      src={ele[1]}
                      alt="blog"
                      className="w-full h-full object-cover rounded"
                    />
                  </div>
                </Link>
              );
            })}
        </div>
        <div className="md:py-16 py-8">
          <div className="font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7 text-center text-[#E4A0F7]">
            CONTACT US
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Blog;

import React, { useEffect, useState } from "react";
import arrowRight from "../icons/arrow-right.png";
import locationIcon from "../icons/Location.png";
import emailLogo from "../icons/Email.svg.png";
import { supabase } from "../utils";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [contactPageData, setContactPageData] = useState({});
  const [companyDetailsData, setCompanyDetailsData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    category: "",
    email: "",
    message: "",
  });

  async function fetchCompanyDetailsData() {
    const { data, error } = await supabase.from("company_details").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setCompanyDetailsData(cloneData);
      // Update your component state with the fetched data
    }
  }

  async function fetchContactusPageData() {
    const { data, error } = await supabase.from("contactus_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setContactPageData(cloneData);
      // Update your component state with the fetched data
    }
  }

  // Call the fetchContactusPageData function when needed, e.g., in a useEffect hook
  useEffect(() => {
    fetchCompanyDetailsData();
    fetchContactusPageData();
  }, []);

  const handleChange = (event) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const postFormData = async (event) => {
    event.preventDefault();
    await supabase.from("message_from_contact_us").insert(formData);
    setFormData({
      name: "",
      number: "",
      category: "",
      email: "",
      message: "",
    });
    toast("Message send successfully");
  };

  return (
    <div className="lg:flex block justify-between items-center md:pt-16 pt-8">
      <div className="relative lg:w-[45%] w-full md:h-[918px] h-[516px]">
        <img
          className="object-cover h-full w-full"
          src={contactPageData.contact_form_img}
          alt="contect"
        />
        <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[918px] h-[516px]"></div>
        <div className="absolute text-white bottom-[8%] left-[5%]">
          <div className="font-[Abel] font-normal md:text-[20px] text-xs md:leading-[18px] leading-3 tracking-[1.5px] text-left">
            Contact Details
          </div>
          <div className="font-semibold md:text-[18px] text-[11px] md:leading-[30px] leading-4 flex gap-4 items-center md:my-6 my-2 md:w-[332px] w-[192px]">
            <img src={locationIcon} alt="location" />
            {companyDetailsData.company_address}
          </div>

          <div className="flex gap-4 font-semibold md:text-[18px] text-[11px] md:leading-[18px] leading-3">
            <img src={emailLogo} alt="emailLogo" />
            {companyDetailsData.company_mail}
          </div>
        </div>
      </div>
      <div className="lg:w-[53%] w-full">
        <form onSubmit={postFormData} className="flex flex-col">
          <input
            className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            required
            onChange={handleChange}
          />
          <input
            className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
            type="number"
            name="number"
            placeholder="Phone Number"
            value={formData.number}
            required
            onChange={handleChange}
          />
          <input
            className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
            type="text"
            name="category"
            placeholder="Category"
            value={formData.category}
            onChange={handleChange}
          />
          <input
            className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <textarea
            className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
            rows={8}
            type="text"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
          <div className="flex md:justify-end justify-center items-center">
            <button
              type="submit"
              className="flex justify-center items-center button-gred text-white h-[60px] w-[195px] rounded-xl my-6 font-semibold text-base"
            >
              Send Message{" "}
              <img src={arrowRight} alt="arrowRight" className="ml-2" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useEffect, useState } from "react";
import arrowRight from "../icons/arrow-right.png";
import { supabase } from "../utils";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";

const LBSInWeb3 = () => {
  const [pageData, setPageData] = useState([]);
  const [companyDetailsData, setCompanyDetailsData] = useState({});

  async function fetchCompanyDetailsData() {
    const { data, error } = await supabase.from("company_details").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setCompanyDetailsData(cloneData);
      // Update your component state with the fetched data
    }
  }

  useEffect(() => {
    fetchCompanyDetailsData();
  }, []);

  async function fetchWeb3Data() {
    const { data, error } = await supabase.from("lbsInWeb3_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPageData(cloneData);
    }
  }

  // Call the fetchWeb3Data function
  useEffect(() => {
    fetchWeb3Data();
  }, []);

  return (
    <div class="relative bg-black">
      <div class="w-full h-full">
        <img
          src={pageData.banner_bg_img}
          alt="lbsInwebBanner"
          class="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {" "}
        {pageData.banner_title}
      </div>
      <div className="container mx-auto  md:p-16 p-4">
        <div className="flex justify-center">
          <div className="text-shadow-neon-pink font-monument-extended font-normal md:text-[56px] text-2xl md:w-[1344px] md:leading-[68px] tracking-normal text-center text-neon-pink pb-8">
            Immerse in the world of METAVERSE
          </div>
        </div>
        <div className="xl:flex gap-4 xl:h-[533px]">
          <div className="xl:w-[50%] sm:h-[533px] h-[255px]">
            <iframe
              className="w-full h-full"
              title="Embedded Video"
              src={pageData.immerse_video_link}
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div className="xl:w-[50%] md:px-8 py-12">
            <div className="font-medium md:text-[28px] text-base md:leading-[32px] leading-5 text-white">
              {pageData.immerse_des}
            </div>
            <div className="flex justify-start items-center">
              <a
                href="https://apesfarfromhome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="submit"
                  className="flex justify-center items-center button-gred-4 text-white h-[55px] w-[195px] rounded-xl mt-4 font-semibold text-base"
                >
                  Know More
                  <img src={arrowRight} alt="arrowRight" className="ml-2" />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="py-24 overflow-x-scroll">
          <div className="w-[2590px] animate-slide-right-left">
            <div className="flex gap-8 py-6">
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px]">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs1.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczEucG5nIiwiaWF0IjoxNzExOTczNDQwLCJleHAiOjEwMzUxODg3MDQwfQ.KK4yWHJ3i3g0RcyQDNTqEYGrTHSjXdJ2-PZhRlj6UJk&t=2024-04-01T12%3A10%3A40.621Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs2.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczIucG5nIiwiaWF0IjoxNzExOTczNDY0LCJleHAiOjEwMzUxODg3MDY0fQ.CvZuu5hFDQrV_Ni38lCr5HKMzviRC5zG0jUhJHcC2No&t=2024-04-01T12%3A11%3A04.680Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs3.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczMucG5nIiwiaWF0IjoxNzExOTczMjYzLCJleHAiOjEwMzUxODg2ODYzfQ.bNUpzXlw51HHmFs3NrvnACrk7s1ZG0726qZuThqJw0M&t=2024-04-01T12%3A07%3A43.552Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs4.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczQucG5nIiwiaWF0IjoxNzExOTczMjgxLCJleHAiOjEwMzUxODg2ODgxfQ.F8m2EAdcP1r5-FaXDTgOvE9DXQNJUsxDd_6S9Q2V1V8&t=2024-04-01T12%3A08%3A01.972Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs5.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczUucG5nIiwiaWF0IjoxNzExOTczMjk5LCJleHAiOjEwMzUxODg2ODk5fQ.KRJ9Q3wRNKRLQRQxXS11mW1TH4TyiSKgL23eHtQr9OY&t=2024-04-01T12%3A08%3A20.224Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs6.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczYucG5nIiwiaWF0IjoxNzExOTczNzY3LCJleHAiOjEwMzUxODg3MzY3fQ.VuRqXnAGg5ZQFYoNXXutLIQ9hDLVLnJy_oIyLGZnXA0&t=2024-04-01T12%3A16%3A07.187Z"
                  alt="card"
                />
              </div>
            </div>

            <div className="flex gap-8 py-6">
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px]">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs7.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczcucG5nIiwiaWF0IjoxNzExOTc0MDk3LCJleHAiOjEwMzUxODg3Njk3fQ.uSSOqaQtA6-GHJrGEKf3yvijMX9CWq05AQUofc_Xo-4&t=2024-04-01T12%3A21%3A37.442Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs8.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczgucG5nIiwiaWF0IjoxNzExOTc0MDU3LCJleHAiOjEwMzUxODg3NjU3fQ.XUzwi2SPnzG00v4GclfdJQQ0RZ3XnwHJQARvhfqRzOY&t=2024-04-01T12%3A20%3A57.998Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs9.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczkucG5nIiwiaWF0IjoxNzExOTc0MjI4LCJleHAiOjEwMzUxODg3ODI4fQ.iV6EpRB0WcL1IiieNXFz3V3iatnoSKVysi-oBiD4_I0&t=2024-04-01T12%3A23%3A48.506Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs10.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczEwLnBuZyIsImlhdCI6MTcxMTk3NDEzNCwiZXhwIjoxMDM1MTg4NzczNH0.QCzMOxSfH6mCRe2opKopPvIyrHlMQhDKa41CTYfI53g&t=2024-04-01T12%3A22%3A14.318Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs11.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczExLnBuZyIsImlhdCI6MTcxMTk3NDE1NiwiZXhwIjoxMDM1MTg4Nzc1Nn0.wPkafMfD6wduj3kO3eL8beSIGgBw7fvtT9X6ptcFf_o&t=2024-04-01T12%3A22%3A36.742Z"
                  alt="card"
                />
              </div>
              <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px] ">
                <img
                  className="object-cover h-full w-full rounded-xl md:rounded-2xl"
                  src="https://kzxozwhjmvakyxhjggvk.supabase.co/storage/v1/object/sign/All%20images/lbs12.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJBbGwgaW1hZ2VzL2xiczEyLnBuZyIsImlhdCI6MTcxMTk3NDIwNCwiZXhwIjoxMDM1MTg4NzgwNH0.FXAQwu39k9QLZVf1iGp8hWg1bpDvRBN9DnZyFitFEMI&t=2024-04-01T12%3A23%3A24.890Z"
                  alt="card"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-12">
          <div className="flex justify-center">
            <div className="text-neon-pink font-monument-extended font-normal md:text-[36px] md:w-[1344px] md:leading-[43px] text-center">
              "9999 NFTs all unique that tell the tale of how our Ape survived
              in the 2.5D World"
            </div>
          </div>
          <div className="md:flex justify-center items-center my-8 gap-8">
            <div className="font-semibold md:text-2xl text-base leading-5 text-center md:leading-7 text-white">
              To know more LBS-in-Web3
            </div>
            <div className="flex md:justify-start justify-center items-center md:my-0 my-4">
              <a
                href="https://apesfarfromhome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="submit"
                  className="flex justify-center items-center button-gred-4 text-white h-[60px] w-[195px] rounded-xl font-semibold text-base"
                >
                  Click Here
                  <img src={arrowRight} alt="arrowRight" className="ml-2" />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="pt-28">
          <div className="flex justify-center">
            <div className="text-neon-pink font-monument-extended font-normal md:text-[40px] md:w-[982px] md:leading-[48px] text-center">
              To get more updates, Follow us on
            </div>
          </div>
          <div className="flex justify-center gap-6 my-8">
            <Link to={companyDetailsData.company_insta} target="_blank">
              <img
                className="w-[64px] h-[64px]"
                src={companyDetailsData.insta_img}
                alt="instaLogo"
              />
            </Link>
            <Link to={companyDetailsData.company_discord} target="_blank">
              <img
                className="w-[64px] h-[64px]"
                src={companyDetailsData.discord_img}
                alt="cordLogo"
              />
            </Link>
            <Link to={companyDetailsData.company_twitter} target="_blank">
              <img
                className="w-[64px] h-[64px]"
                src={companyDetailsData.twitter_img}
                alt="twitLogo"
              />
            </Link>
          </div>
        </div>
        <div className="md:py-24 py-8">
          <div className="font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7 text-center text-[#E4A0F7]">
            CONTACT US
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default LBSInWeb3;

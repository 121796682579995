import React, { useEffect, useState } from "react";
import { supabase } from "../utils";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  const [pageData, setPageData] = useState([]);

  async function fetchContactus() {
    const { data, error } = await supabase.from("contactus_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPageData(cloneData);
    }
  }

  // Call the fetchContactus function
  useEffect(() => {
    fetchContactus();
  }, []);

  return (
    <div class="relative bg-black">
      <div class="w-full h-full">
        <img
          src={pageData.banner_bg_img}
          alt="about_banner"
          class="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {" "}
        {pageData.banner_title}
      </div>
      <div className="container mx-auto  md:p-16 p-4">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import arrowLeft from "../icons/arrow-left.svg";
import calendar from "../icons/calendar.svg";
import profile from "../icons/profile-circle.svg";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { customSort, supabase } from "../utils";

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [pageData, setPageData] = useState([]);
  const [blogArr, setBlogArr] = useState([]);

  async function fetchBlogData() {
    const { data, error } = await supabase.from("blog_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPageData(cloneData);

      const arr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "blog" &&
          key.split("_")[1] === id &&
          key.split("_")[2] === "blogdetails" &&
          key.split("_")[3] === "img"
      );
      arr.sort(customSort);
      setBlogArr(arr);
    }
  }

  // Call the fetchBlogData function
  useEffect(() => {
    fetchBlogData();
    // eslint-disable-next-line
  }, []);

  return (
    <div class="relative bg-black">
      <div className="container mx-auto  md:p-16 p-4">
        <div className="pt-[150px]">
          <Link to="/blog">
            <img src={arrowLeft} alt="arrowLeft" />
          </Link>
        </div>
        <div className="flex flex-wrap gap-4 py-8">
          {blogArr.length ? (
            blogArr.map((ele) => {
              return (
                <div className="md:w-[380px] md:h-[750px] h-[550px] w-full mx-auto 2xl:mx-0">
                  <img
                    src={ele[1]}
                    alt="blogDetails"
                    className="w-full h-full object-cover rounded-3xl"
                  />
                </div>
              );
            })
          ) : (
            <div className="text-white">No Image Available</div>
          )}
        </div>
        <div className="font-monument-extended font-normal md:text-[40px] text-xl md:leading-[48px] leading-6 text-white">
          {pageData[`blog_${id}_blogdetails_title`]}
        </div>
        <div className="md:flex items-center gap-8 py-12">
          <div className="flex items-center gap-2 md:py-0 py-2">
            {pageData[`blog_${id}_blogdetails_date`] && (
              <img src={calendar} alt="calendar" />
            )}
            <div className="text-neon-pink font-semibold text-base leading-5">
              {pageData[`blog_${id}_blogdetails_date`]}
            </div>
          </div>
          <div className="flex items-center gap-2 md:py-0 py-2">
            {pageData[`blog_${id}_blogdetails_name`] && (
              <img src={profile} alt="profile" />
            )}
            <div className="text-neon-pink font-semibold text-base leading-5">
              {pageData[`blog_${id}_blogdetails_name`]}
            </div>
          </div>
        </div>
        <div className="font-medium md:text-[28px] text-base md:leading-[34px] text-white pb-12">
          {pageData[`blog_${id}_blogdetails_des`] ? (
            pageData[`blog_${id}_blogdetails_des`]
          ) : (
            <div className="text-white">No Details Available</div>
          )}
        </div>
        <div className="md:py-16 py-8">
          <div className="font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px] leading-7 text-center text-[#E4A0F7]">
            CONTACT US
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;

import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_PROJECT_URL;
const supabaseKey = process.env.REACT_APP_API_KEY;
export const supabase = createClient(supabaseUrl, supabaseKey);

export function customSort(a, b) {
  // Extract the numeric part from the strings (e.g., "project_des_1" -> 1)
  const numA = parseInt(a[0].split("_").pop(), 10);
  const numB = parseInt(b[0].split("_").pop(), 10);

  return numA - numB; // Sort based on the numeric part
}

import React, { useEffect, useState } from "react";
import nextArrow from "../icons/arrow-square-right.svg";
import prevArrow from "../icons/arrow-square-left.svg";
import arrowRight from "../icons/arrow-right.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../utils";

const Careers = () => {
  // const [isDepartmentsDropdown, setIsDepartmentsDropdown] = useState(false);
  const [isJobTypeDropdown, setIsJobTypeDropdown] = useState(false);
  // const [isLocationDropdown, setIsLocationDropdown] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("Job Type");
  const [pageData, setPageData] = useState([]);
  const pageSize = 8; // Number of rows to show per page
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    category: "",
    email: "",
    message: "",
  });

  // Function to update search query
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      // Filtering logic based on search query
      const filteredRole = jobs.filter((job) =>
        job.role.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const filteredExperience = jobs.filter((job) =>
        job.experience.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const filteredJobtype = jobs.filter((job) =>
        job.job_type.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const result = [
        ...filteredRole,
        ...filteredExperience,
        ...filteredJobtype,
      ];
      setFilteredData(result);
    } else {
      setFilteredData(jobs);
    }
  };

  const getTotalPages = (totalItems, pageSize) => {
    return Math.ceil(totalItems / pageSize);
  };

  const totalPages = getTotalPages(filteredData.length, pageSize);

  // Calculate start and end index based on current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);

  // Get the rows to display on the current page
  const currentPageData = filteredData.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  async function fetchCarrersData() {
    const { data, error } = await supabase.from("carrers_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setPageData(cloneData);
    }
  }

  // Call the fetchCarrersData function
  useEffect(() => {
    fetchCarrersData();
  }, []);

  async function fetchCarrersJobs() {
    const { data, error } = await supabase.from("carrers_job").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      setJobs(data);
      setFilteredData(data);
    }
  }

  // Call the fetchCarrersJobs function
  useEffect(() => {
    fetchCarrersJobs();
  }, []);

  const handleChange = (event) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const postFormData = async (event) => {
    event.preventDefault();
    await supabase.from("message_from_contact_us").insert(formData);
    setFormData({
      name: "",
      number: "",
      category: "",
      email: "",
      message: "",
    });
    toast("Message send successfully");
  };

  // const toggleDepartmentsDropdown = () => {
  //   setIsDepartmentsDropdown(!isDepartmentsDropdown);
  // };

  const toggleJobTypeDropdown = () => {
    setIsJobTypeDropdown(!isJobTypeDropdown);
  };
  // const toggleLocationDropdown = () => {
  //   setIsLocationDropdown(!isLocationDropdown);
  // };
  const jobTypeFilter = (type) => {
    setSelectedJobType(type);
    if (type !== "Job Type") {
      const filterJobs = jobs.filter((item) => item.job_type === type);
      setFilteredData(filterJobs);
    } else {
      setFilteredData(jobs);
    }
    setIsJobTypeDropdown(!isJobTypeDropdown);
  };

  return (
    <div class="relative bg-black">
      <div class="w-full h-full">
        <img
          src={pageData.banner_bg_img}
          alt="about_banner"
          class="w-full md:h-[420px] h-[184px] object-cover"
        />
      </div>

      <div class="absolute inset-0 bg-[#333] opacity-50 md:h-[420px] h-[184px]"></div>
      <div className="absolute md:top-[18rem] top-[8rem] left-[0px] right-[0] container mx-auto text-center text-[#E4A0F7] font-monument-extended font-normal md:text-[64px] text-2xl md:leading-[77px]">
        {pageData.banner_title}
      </div>
      <div className="container mx-auto  md:p-16 p-4">
        <div className="text-neon-pink-small md:text-4xl text-base font-semibold md:leading-[43px] leading-5">
          want to join us?
        </div>
        <div>
          <div className="flex md:gap-8 gap-2 md:py-8 py-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              className="p-4 rounded-[9px] md:w-[804px] md:h-[72px] h-[48px] w-full placeholder:text-[#000] md:placeholder:text-2xl placeholder:text-sm placeholder:font-[400]"
              placeholder="Search by Role, Experience, job Type..."
            />
            <button className="md:w-[200px] md:h-[72px] h-[48px] w-full rounded-lg font-semibold md:text-[32px] text-base md:leading-10 leading-5 text-white bg-[#E4A0F7]">
              Search
            </button>
          </div>
          <div className="flex flex-wrap md:gap-16 gap-4 md:py-4">
            <div className="relative inline-block text-left">
              <button
                type="button"
                className="md:w-[280px] md:h-[72px] w-[181px] h-[48px] inline-flex justify-between items-center w-full rounded-lg text-black shadow-sm px-4 py-2 bg-white md:text-2xl text-base md:font-medium font-semibold leading-[30px] hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200 active:text-gray-800 transition ease-in-out duration-150"
                onClick={toggleJobTypeDropdown}
                aria-expanded={isJobTypeDropdown}
              >
                {selectedJobType}
                {/* Heroicon name: chevron-down */}
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9998 1H15.0048C19.9698 1 22.0098 4.525 19.5198 8.83L17.5098 12.295L15.4998 15.76C13.0098 20.065 8.94483 20.065 6.45483 15.76L4.44483 12.295L2.43483 8.83C-0.0101683 4.525 2.01483 1 6.99483 1H10.9998Z"
                    fill="black"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              {/* Dropdown menu */}
              {isJobTypeDropdown && (
                <div className="w-[280px] absolute right-0 mt-2 w-56 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition duration-300 transform scale-100">
                  <div className="py-1" role="none">
                    {/* Dropdown items */}
                    <div
                      onClick={() => jobTypeFilter("Job Type")}
                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Job Type
                    </div>
                    <div
                      onClick={() => jobTypeFilter("Full Time")}
                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Full Time
                    </div>
                    <div
                      onClick={() => jobTypeFilter("Intern")}
                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Intern
                    </div>
                    <div
                      onClick={() => jobTypeFilter("Freelancer")}
                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Freelancer
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="py-10 text-white text-center">
          <div className="flex justify-between items-center border-gred-y-2 py-10">
            <div className="w-[33%] font-normal text-sm md:text-[32px] leading-3 md:leading-[38px] font-monument-extended">
              Role
            </div>
            <div className="w-[33%] font-normal text-sm md:text-[32px] leading-3 md:leading-[38px] font-monument-extended">
              Experience
            </div>
            <div className="w-[33%] font-normal text-sm md:text-[32px] leading-3 md:leading-[38px] font-monument-extended">
              job Type
            </div>
          </div>
          {currentPageData.length &&
            currentPageData.map((item, index) => {
              return (
                <Link key={index} to="/careersapplication">
                  <div className="flex justify-around items-center border-b-2 border-white py-10">
                    <div className="w-[33%] font-semibold md:text-[32px] text-sm	leading-4 md:leading-[39px]">
                      {item.role}
                    </div>
                    <div className="w-[33%] font-semibold md:text-[32px] text-sm	leading-4 md:leading-[39px]">
                      {item.experience}
                    </div>
                    <div className="w-[33%] font-semibold md:text-[32px] text-sm	leading-4 md:leading-[39px]">
                      {item.job_type}
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
        <div className="flex justify-center items-center py-4">
          <div className="flex gap-10">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={prevArrow} alt="prevArrow" />
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <div
                className={`
                  ${
                    currentPage === index + 1 ? "text-[#E4A0F7]" : "text-white"
                  } font-semibold text-4xl leading-[44px]`}
              >
                {index + 1}
              </div>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={endIndex >= jobs.length}
            >
              <img src={nextArrow} alt="nextArrow" />
            </button>
          </div>
        </div>

        <div className="py-32">
          <div className="font-monument-extended font-normal text-[#E4A0F7] md:text-[40px] text-2xl md:leading-[48px] leading-7 md:text-center">
            For Business Related Enquiry
          </div>
          <form onSubmit={postFormData} className="flex flex-col md:pt-8 pt-4">
            <div className="md:flex gap-24">
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                required
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                required
                type="number"
                name="number"
                placeholder="Phone Number"
                value={formData.number}
                onChange={handleChange}
              />
            </div>
            <div className="md:flex gap-24">
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                type="text"
                name="category"
                placeholder="Category"
                value={formData.category}
                onChange={handleChange}
              />
            </div>

            <textarea
              className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
              rows={7}
              type="text"
              name="message"
              value={formData.message}
              placeholder="Message"
              onChange={handleChange}
            />
            <div className="flex md:justify-center justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center button-gred text-white h-[60px] w-[195px] rounded-xl my-6 font-semibold text-base"
              >
                Send Message{" "}
                <img src={arrowRight} alt="arrowRight" className="ml-2" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Careers;

import React, { useEffect, useState, useRef } from "react";
import previx from "../icons/Group 1171274894 (1).svg";
import assets from "../icons/Group 1171275038.svg";
import crowds from "../icons/Group 1171275039.svg";
import animation from "../icons/Group 1171275040 (1).svg";
import environment from "../icons/Group 1171275041.svg";
import fx from "../icons/Group 1171275042.svg";
import lighting from "../icons/Group 1171275043 (1).svg";
import motion from "../icons/Group 1171275044.svg";
import { supabase } from "../utils";

const Services = () => {
  // const [hoveredImage, setHoveredImage] = useState(null);
  const [servicesPageData, setServicesPageData] = useState([]);
  const [pointPosition, setPointPosition] = useState(20);
  const [activateText, setActivateText] = useState("0");
  const [scrollActive, setScrollActive] = useState(true);
  const containerRef = useRef(null);
  const divRefs = useRef([]);

  async function fetchServicesData() {
    const { data, error } = await supabase.from("services_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setServicesPageData(cloneData);
    }
  }

  // Call the fetchPosterLink function
  useEffect(() => {
    fetchServicesData();
  }, []);

  const handleImageClick = (e, index) => {
    setScrollActive(false); // Disable scroll event listener
    // Calculate the index of the card closest to the midpoint

    setActivateText(`${index}`);
    const rect = divRefs.current[index].getBoundingClientRect();
    setPointPosition((rect.left + rect.right) / 2);
    const cardId = index.toString(); // Convert index to string
    const card = document.getElementById(cardId);
    if (!card) {
      console.error(`Card with id ${cardId} not found`);
      return;
    }

    const container = document.querySelector(".images-container");

    // Calculate the scroll position to center the clicked card
    const scrollPosition =
      card.offsetLeft - (container.offsetWidth - card.offsetWidth) / 2;

    // Scroll the container to the calculated position
    container.scrollTo({
      left: scrollPosition,
      behavior: "smooth",
    });

    // Re-enable scroll after a delay
    setTimeout(() => {
      container.scrollTo({
        left: scrollPosition + 0.7,
        behavior: "smooth",
      });
      setScrollActive(true);
    }, 800); // Adjust the delay as needed
  };

  const handleHover = (imageIndex) => {
    const rect = divRefs.current[imageIndex].getBoundingClientRect();
    setPointPosition((rect.left + rect.right) / 2);
    setActivateText(imageIndex);
    // setHoveredImage(imageIndex);
    const cards = document.querySelectorAll(".card");
    let elementIndex = "";
    cards.length &&
      cards.forEach((ele, ind) => {
        if (imageIndex === ele.id) {
          elementIndex = ind;
        } else if (imageIndex !== null && `${elementIndex}`) {
          ele.classList.add("shift-left");
        } else {
          ele.classList.remove("shift-left");
        }
      });
  };

  const handleMouseLeave = () => {
    const cards = document.querySelectorAll(".card");
    cards.length &&
      cards.forEach((ele, ind) => {
        ele.classList.remove("shift-left");
      });
  };

  useEffect(() => {
    if (!scrollActive) return; // Exit if scroll is inactive
    const handleScroll = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const cardWidth = 400; // Adjust according to your card width
      const scrollPosition = containerRef.current.scrollLeft;
      const totalScrollableWidth = containerRef.current.scrollWidth;
      const remainingWidth = totalScrollableWidth - containerWidth;
      const centerX = window.innerWidth / 2;
      let index = 0;

      // Check if scrolled to the end
      if (scrollPosition >= remainingWidth - 1150) {
        // Move the first card to the end
        const cardsWrapper = document.querySelector(".cards-wrapper");
        const firstCard = cardsWrapper.firstElementChild;
        const clonedCard = firstCard.cloneNode(true);
        clonedCard.addEventListener("mouseenter", () =>
          handleHover(firstCard.id)
        );
        clonedCard.addEventListener("mouseleave", () => handleMouseLeave());
        cardsWrapper.appendChild(clonedCard);
        // Remove the first card
        firstCard.remove();
        // Reset scroll position to maintain the scroll position after appending
        containerRef.current.scrollTo(scrollPosition - cardWidth, 0);
      }

      const cards = document.querySelectorAll(".card");
      cards.length &&
        cards.forEach((ele, ind) => {
          const rec = ele.getBoundingClientRect();
          if (centerX > rec.left) {
            index = ele.id;
          }
        });

      // Calculate the index of the card closest to the midpoint
      setActivateText(`${index}`);

      const rect = divRefs.current[index].getBoundingClientRect();
      setPointPosition((rect.left + rect.right) / 2);
    };

    const containerElement = containerRef.current;
    containerElement.addEventListener("scroll", handleScroll);

    return () => {
      containerElement.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [scrollActive]);

  return (
    <div className="relative items-center w-[100vw] md:h-[1117px] h-[899px] relative bg-black pb-16">
      <div className="absolute top-[16%] left-[5%] text-[#E4A0F7] font-monument-extended font-normal md:text-[40px] text-3xl md:leading-[48px]">
        SERVICES
      </div>

      <div
        className="absolute top-[30%] w-[100vw] overflow-x-scroll h-[70%] images-container"
        ref={containerRef}
      >
        <div className="md:w-[3700px] w-[3200px] flex flex-col justify-between h-full scroll-container">
          <div className="flex gap-16 pl-12 cards-wrapper">
            <div
              id="0"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("0")}
              onMouseLeave={handleMouseLeave}
            >
              <img className="w-full h-full object-cover" src={fx} alt="fx" />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_6}
              </div>
            </div>

            <div
              id="1"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("1")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={animation}
                alt="animation"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_4}
              </div>
            </div>

            <div
              id="2"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("2")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={environment}
                alt="environment"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_5}
              </div>
            </div>

            <div
              id="3"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("3")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={previx}
                alt="previx"
              />

              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_1}
              </div>
            </div>

            <div
              id="4"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("4")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={assets}
                alt="assets"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_2}
              </div>
            </div>

            <div
              id="5"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("5")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={crowds}
                alt="crowds"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_3}
              </div>
            </div>

            <div
              id="6"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("6")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={lighting}
                alt="lighting"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_7}
              </div>
            </div>

            <div
              id="7"
              className={`card w-[400px] h-[350px] flex serviceImage items-center`}
              onMouseEnter={(e) => handleHover("7")}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="w-full h-full object-cover"
                src={motion}
                alt="motion"
              />
              <div className="text-white w-[1150px] font-semibold leading-9 text-3xl serviceImage-overlay">
                {servicesPageData.services_des_8}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[80%] w-[100vw] md:overflow-x-clip overflow-x-scroll h-[80px] relative">
        <div className="w-[3700px] border-gred-2 md:w-full">
          <div
            style={{ left: pointPosition, transition: "left 0.5s ease" }}
            className={`md:block hidden absolute top-[-4px] bg-neon-pink rounded-[50%] w-[12px] h-[12px]`}
          ></div>
        </div>

        <div className="flex justify-between w-[3700px] md:w-full">
          <div
            ref={(element) => (divRefs.current["0"] = element)}
            onClick={(e) => handleImageClick(e, "0")}
            className={`${
              activateText === "0"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">FX</span>
          </div>
          <div
            ref={(element) => (divRefs.current["1"] = element)}
            onClick={(e) => handleImageClick(e, "1")}
            className={`${
              activateText === "1"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">Animation</span>
          </div>
          <div
            ref={(element) => (divRefs.current["2"] = element)}
            onClick={(e) => handleImageClick(e, "2")}
            className={`${
              activateText === "2"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">Environment</span>
          </div>
          <div
            ref={(element) => (divRefs.current["3"] = element)}
            onClick={(e) => handleImageClick(e, "3")}
            className={`${
              activateText === "3"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">Previsualization</span>
          </div>
          <div
            ref={(element) => (divRefs.current["4"] = element)}
            onClick={(e) => handleImageClick(e, "4")}
            className={`${
              activateText === "4"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">ASSETS</span>
          </div>
          <div
            ref={(element) => (divRefs.current["5"] = element)}
            onClick={(e) => handleImageClick(e, "5")}
            className={`${
              activateText === "5"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">CROWDS</span>
          </div>
          <div
            ref={(element) => (divRefs.current["6"] = element)}
            onClick={(e) => handleImageClick(e, "6")}
            className={`${
              activateText === "6"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">LIGHTING AND COMPOSITION</span>
          </div>
          <div
            ref={(element) => (divRefs.current["7"] = element)}
            onClick={(e) => handleImageClick(e, "7")}
            className={`${
              activateText === "7"
                ? "text-white animate-pulse"
                : "text-slate-600"
            } uppercase font-semibold cursor-pointer text-base leading-5 bottom-[15%] text-center m-4`}
          >
            <span className="my-2 inline-block">MOTION GRAPHICS</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

import React, { useCallback, useEffect, useRef, useState } from "react";
import reel from "../icons/reel.svg";
import leftArrow from "../icons/arrow-left-white.svg";
import rightArrow from "../icons/arrow-right-white.svg";
import render from "../icons/Group 1171274853.svg";
import brace from "../icons/623c95682a8f369e0a4ca80b_Quotes.svg.png";
import arrowRight from "../icons/arrow-right.png";
// import arrowRight from "../icons/arrow-right.png";
import Services from "./Services";
import { supabase, customSort } from "../utils";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Home = () => {
  const [changeText, setChangeText] = useState(true);
  const [selectedImage, setSelectedImage] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [homePageData, setHomePageData] = useState({});
  const [projectImgArr, setProjectImgArr] = useState([]);
  const [projectbgImgArr, setProjectbgImgArr] = useState([]);
  const [projectTextArr, setProjectTextArr] = useState([]);
  const [resourcesTitle, setResourcesTitle] = useState([]);
  const [resourcesDes, setResourcesDes] = useState([]);
  const [lovedImg, setLovedImg] = useState([]);
  const [lovedDes, setLovedDes] = useState([]);
  const [selectedLoved, setSelectedLoved] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const scrollRef = useRef(null);
  const section2Ref = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    category: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const postFormData = async (event) => {
    event.preventDefault();
    await supabase.from("message_from_contact_us").insert(formData);
    setFormData({
      name: "",
      number: "",
      category: "",
      email: "",
      message: "",
    });
    toast("Message send successfully");
  };

  async function fetchHomePageData() {
    const { data, error } = await supabase.from("home_page").select("*");

    if (error) {
      console.error("Error fetching data:", error.message);
    } else {
      let cloneData = {};
      data.map((item) => {
        return (cloneData = {
          ...cloneData,
          [item.resource_key]: item.resource_value,
        });
      });
      setHomePageData(cloneData);

      const arr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "project" && key.split("_")[1] === "front"
      );
      arr.sort(customSort);
      setProjectImgArr(arr);

      const arrBack = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "project" && key.split("_")[1] === "back"
      );
      arrBack.sort(customSort);
      setProjectbgImgArr(arrBack);

      const arrText = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "project" && key.split("_")[1] === "des"
      );
      arrText.sort(customSort);
      setProjectTextArr(arrText);

      const arrtitle = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "resources" && key.split("_")[1] === "title"
      );
      arrtitle.sort(customSort);
      setResourcesTitle(arrtitle);

      const arrDes = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "resources" && key.split("_")[1] === "des"
      );
      arrDes.sort(customSort);
      setResourcesDes(arrDes);

      const lovedImgArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "loved" && key.split("_")[1] === "img"
      );
      lovedImgArr.sort(customSort);
      setLovedImg(lovedImgArr);

      const lovedDesArr = Object.entries(cloneData).filter(
        ([key, value]) =>
          key.split("_")[0] === "loved" && key.split("_")[1] === "des"
      );
      lovedDesArr.sort(customSort);
      setLovedDes(lovedDesArr);

      // Update your component state with the fetched data
    }
  }

  useEffect(() => {
    // Autoplay the video on page load
    videoRef.current.play().catch((error) => {
      console.error("Play error:", error.message);
    });
    setIsPlaying(true);
  }, []);

  // Call the fetchHomePageData function when needed, e.g., in a useEffect hook
  useEffect(() => {
    fetchHomePageData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedImage(1);
    // Function to handle scroll event
    const handleScroll = () => {
      if (scrollRef.current.scrollTop > 100) {
        setChangeText(false);
      } else {
        setChangeText(true);
      }
    };
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        // eslint-disable-next-line
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once on initial render

  const handleImageClickRight = useCallback(() => {
    if (projectImgArr.length && projectbgImgArr.length) {
      let copyProjectImgArr = [...projectImgArr];
      let copyProjectbgImgArr = [...projectbgImgArr];
      let copyProjectTextArr = [...projectTextArr];
      const shiftedElement = copyProjectImgArr.shift();
      const bgShiftElement = copyProjectbgImgArr.shift();
      const textShiftElement = copyProjectTextArr.shift();
      copyProjectImgArr.push(shiftedElement);
      copyProjectbgImgArr.push(bgShiftElement);
      copyProjectTextArr.push(textShiftElement);
      setProjectImgArr(copyProjectImgArr);
      setProjectbgImgArr(copyProjectbgImgArr);
      setProjectTextArr(copyProjectTextArr);
    }
  }, [projectImgArr, projectTextArr, projectbgImgArr]);

  const handleImageClickLeft = () => {
    let copyProjectImgArr = [...projectImgArr];
    let copyProjectbgImgArr = [...projectbgImgArr];
    let copyProjectTextArr = [...projectTextArr];
    const shiftedElement = copyProjectImgArr.pop();
    const bgShiftElement = copyProjectbgImgArr.pop();
    const textShiftElement = copyProjectTextArr.pop();
    copyProjectImgArr.unshift(shiftedElement);
    copyProjectbgImgArr.unshift(bgShiftElement);
    copyProjectTextArr.unshift(textShiftElement);
    setProjectImgArr(copyProjectImgArr);
    setProjectbgImgArr(copyProjectbgImgArr);
    setProjectTextArr(copyProjectTextArr);
  };

  useEffect(() => {
    if (projectImgArr.length) {
      const timeoutId = setTimeout(() => {
        handleImageClickRight();
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [projectImgArr, handleImageClickRight]);

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const handleSelectedIndex = (index) => {
    setSelectedLoved(index);
  };

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef?.current && videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef?.current && videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // Function to scroll to the next section
  const scrollToNextSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div class="relative bg-black">
        <div class={`w-[100vw] md:h-[100vh] h-[100vh]`}>
          {/* <iframe
            className="w-full h-full"
            title="Embedded Video"
            src={homePageData.video_link}
            frameborder="0"
            allowfullscreen
          ></iframe> */}
          <video
            className="w-full h-full object-cover"
            ref={videoRef}
            src={homePageData.video_link}
            controls={false}
            muted
            autoPlay
            loop
          />
        </div>

        <div
          ref={scrollRef}
          className="w-[100vw] h-[100vh] overflow-scroll absolute top-[0]"
        >
          <div className={`xl:px-[5%] px-4 h-[130vh]`}>
            <div
              className={`sticky text-white xl:top-[52%] top-[65%] ${
                changeText ? "fade-in" : "fade-out"
              }`}
            >
              <div className="font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px]">
                Unleashing Visual
                <br />
                <span className="bg-neon-pink px-6 py-1 font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px]">
                  Wonders
                </span>{" "}
                Where
                <br />
                Your Imagination <br />
                meets{" "}
                <span className="font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px] text-[#00FFFF]">
                  Reality
                </span>
              </div>

              <button
                onClick={togglePlay}
                className="flex justify-center items-center border-gred-transparent-2 w-[220px] h-[52px] rounded-xl	mt-4 font-semibold text-base leading-5	"
              >
                {isPlaying ? "Pause" : "Watch"} Show Reel
                <img src={reel} alt="reel" className="ml-3" />
              </button>
            </div>

            <div
              className={`sticky text-white xl:top-[52%] top-[65%] ${
                changeText ? "fade-out" : "fade-in"
              }`}
            >
              <div className="uppercase font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px]">
                Unlocking{" "}
                <span className="uppercase font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px] text-[#E4A0F7]">
                  Stories
                </span>
                <br />
                BY Crafting{" "}
                <span className="uppercase font-monument-extended font-normal md:text-[40px] text-2xl md:leading-[60px] text-[#00FFFF]">
                  Worlds
                </span>
              </div>
              <div className="font-medium md:text-xl text-base md:leading-6 md:w-[625px]">
                Little Black Star visual effects is a TPN certified studio.We
                specialize in creating stunning and realistic visual effects for
                various forms of media, including films, television shows,
                commercials, music videos, video games, and more. Our main focus
                is on enhancing visual storytelling and immersing audiences in
                captivating and imaginative worlds.
              </div>
              <Link to="/contact">
                <button
                  className={`${
                    changeText ? "hidden" : "block"
                  } flex justify-center items-center button-gred-4 w-[164px] h-[52px] rounded-xl	mt-4 font-semibold text-base leading-5`}
                >
                  Contact Us
                  <img src={arrowRight} alt="arrowRight" className="ml-2" />
                </button>
              </Link>
            </div>

            {/* <div className="md:absolute md:block flex justify-end right-[11%] top-[74%]">
            <img src={scrollDown} alt="scrollDown" />
          </div> */}
            <div className={`sticky md:top-[75%] top-[85%] flex justify-end`}>
              <div
                onClick={() => scrollToNextSection(section2Ref)}
                class="md:absolute md:block top-[75%] arrow"
              >
                <span></span>
                <span></span>
                <span></span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={section2Ref}>
        <Services />
      </div>

      <div
        className={`relative flex justify-between items-center w-full h-[1117px] bg-cover bg-top`}
        style={{
          backgroundImage: `url(${
            projectbgImgArr[selectedImage] && projectbgImgArr[selectedImage][1]
          })`,
        }}
      >
        <div className="absolute inset-0 bg-[#000] opacity-50 h-[1117px]"></div>
        <div className="absolute top-[14%] left-[5%] text-[#E4A0F7] font-monument-extended font-normal md:text-[40px] text-3xl md:leading-[48px]">
          PROJECTS
        </div>
        <div
          className="cursor-pointer z-10 md:w-[340px] w-[160px] md:h-[420px] h-[200px]"
          onClick={() => handleImageClickLeft()}
        >
          <img
            className="w-full h-full obect-cover"
            src={
              projectImgArr[selectedImage - 1] &&
              projectImgArr[selectedImage - 1][1]
            }
            alt="project"
          />
        </div>
        <div className="cursor-pointer z-10 md:w-[562px] w-[240px] md:h-[702px] h-[300px]">
          <img
            className="w-full h-full obect-cover"
            src={
              projectImgArr[selectedImage] && projectImgArr[selectedImage][1]
            }
            alt="project"
          />
        </div>
        <div
          className="cursor-pointer z-10 md:w-[340px] w-[160px] md:h-[420px] h-[200px]"
          onClick={() => handleImageClickRight()}
        >
          <img
            className="w-full h-full obect-cover"
            src={
              projectImgArr[selectedImage + 1] &&
              projectImgArr[selectedImage + 1][1]
            }
            alt="project"
          />
        </div>
        <div className="absolute z-20 top-[71%] left-[8%] text-white md:text-[108px] text-[32px] md:leading-[131px] md:font-extrabold font-normal">
          {projectTextArr[selectedImage] && projectTextArr[selectedImage][1]}
        </div>
        <div className="flex gap-4 top-[89%] right-[8%] absolute">
          {selectedImage && (
            <img
              className="animate-bounce cursor-pointer"
              src={leftArrow}
              alt="leftArrow"
              onClick={() => handleImageClickRight()}
            />
          )}
          {selectedImage - 2 && (
            <img
              className="animate-bounce cursor-pointer"
              src={rightArrow}
              alt="leftArrow"
              onClick={() => handleImageClickLeft()}
            />
          )}
        </div>
      </div>

      <div className="relative flex items-center justify-center w-[100vw] h-[1117px] relative bg-black">
        <div className="absolute uppercase top-[16%] left-[5%] text-[#E4A0F7] font-monument-extended font-normal md:text-[40px] text-3xl md:leading-[48px]">
          Resources
        </div>
        <div className="overflow-x-scroll">
          <div className="container md:w-full w-[300vw] mx-auto md:px-24 px-4 flex gap-16">
            {resourcesTitle.length &&
              resourcesTitle.map((ele, index) => {
                return (
                  <div
                    className={`${
                      index === expandedIndex
                        ? "bg-[#E4A0F7] p-8 flex justify-center items-center w-[585px] h-[600px] rounded-[36px]"
                        : "bg-[#7a7a7a] flex justify-center items-center w-[250px] h-[600px] rounded-[36px]"
                    }`}
                  >
                    <div
                      className="cursor-pointer"
                      onMouseEnter={() => handleExpand(index)}
                    >
                      {index === expandedIndex && (
                        <img src={render} alt="render" />
                      )}
                      <div
                        className={`${
                          index === expandedIndex
                            ? "font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px] my-4"
                            : "rotate-[270deg] font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px]"
                        }`}
                      >
                        {ele[1]}
                      </div>
                      {index === expandedIndex && (
                        <div className="text-white font-semibold md:text-2xl text-base md:leading-8">
                          {resourcesDes[index][1]}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

            {/* <div
              className={`${
                1 === expandedIndex
                  ? "bg-[#E4A0F7] p-8 flex justify-center items-center w-[585px] h-[600px] rounded-[36px]"
                  : "bg-[#7a7a7a] flex justify-center items-center w-[250px] h-[600px] rounded-[36px]"
              }`}
            >
              <div className="cursor-pointer" onClick={() => handleExpand(1)}>
                {1 === expandedIndex && <img src={render} alt="render" />}
                <div
                  className={`${
                    1 === expandedIndex
                      ? "font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px] my-4"
                      : "rotate-[270deg] font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px]"
                  }`}
                >
                  Pipeline Development
                </div>
                {1 === expandedIndex && (
                  <div className="text-white font-semibold md:text-2xl text-base md:leading-8">
                    Benefit from our state-of-the-art render technology. We
                    harness the latest tools and techniques to deliver top-notch
                    quality and efficiency.
                  </div>
                )}
              </div>
            </div>

            <div
              className={`${
                2 === expandedIndex
                  ? "bg-[#E4A0F7] p-8 flex justify-center items-center w-[585px] h-[600px] rounded-[36px]"
                  : "bg-[#7a7a7a] flex justify-center items-center w-[250px] h-[600px] rounded-[36px]"
              }`}
            >
              <div className="cursor-pointer" onClick={() => handleExpand(2)}>
                {2 === expandedIndex && <img src={render} alt="render" />}
                <div
                  className={`${
                    2 === expandedIndex
                      ? "font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px] my-4"
                      : "rotate-[270deg] font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px]"
                  }`}
                >
                  Data Protection
                </div>
                {2 === expandedIndex && (
                  <div className="text-white font-semibold md:text-2xl text-base md:leading-8">
                    Benefit from our state-of-the-art render technology. We
                    harness the latest tools and techniques to deliver top-notch
                    quality and efficiency.
                  </div>
                )}
              </div>
            </div>

            <div
              className={`${
                3 === expandedIndex
                  ? "bg-[#E4A0F7] p-8 flex justify-center items-center w-[585px] h-[600px] rounded-[36px]"
                  : "bg-[#7a7a7a] flex justify-center items-center w-[250px] h-[600px] rounded-[36px]"
              }`}
            >
              <div className="cursor-pointer" onClick={() => handleExpand(3)}>
                {3 === expandedIndex && <img src={render} alt="render" />}
                <div
                  className={`${
                    3 === expandedIndex
                      ? "font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px] my-4"
                      : "rotate-[270deg] font-normal font-monument-extended md:text-4xl text-2xl md:leading-[43px]"
                  }`}
                >
                  Round-the-Clock Access
                </div>
                {3 === expandedIndex && (
                  <div className="text-white font-semibold md:text-2xl text-base md:leading-8">
                    Benefit from our state-of-the-art render technology. We
                    harness the latest tools and techniques to deliver top-notch
                    quality and efficiency.
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {homePageData.isVisible === "true" && (
        <div className="bg-black-fade-effect">
          <div className="flex flex-col items-center justify-end w-[100vw] h-[1117px] relative bg-purple-green-270 px-4">
            <div className="absolute uppercase sm:top-[16%] top-[8%] left-[5%] text-[#E4A0F7] font-monument-extended font-normal md:text-[40px] text-4xl md:leading-[48px]">
              LOVED and TRUSTED
            </div>
            {lovedImg.length &&
              lovedImg.map((ele, index) => {
                return (
                  <>
                    {selectedLoved === index && (
                      <div className="xl:flex gap-16 w-[90%] justify-center">
                        <div className="xl:w-[506px] w-[200px] xl:h-[539px] h-[270px]">
                          <img
                            className="w-full h-full object-cover"
                            src={ele[1]}
                            alt="loved&trusted"
                          />
                        </div>
                        <div>
                          <img
                            src={brace}
                            alt="brace"
                            className="mt-20 mb-16"
                          />
                          <div className="font-normal md:text-2xl text-base md:leading-8 text-white md:w-[700px]">
                            {lovedDes[index][1]}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}

            <div className="xl:mb-20 mb-10 2xl:w-[90%] w-[98%] xl:mt-0 mt-20">
              <div className="relative border border-white mb-4">
                <div className="w-[10px] h-[10px] rounded-[50%] bg-white absolute bottom-[-5px] left-[-1px]"></div>
                <div className="w-[10px] h-[10px] rounded-[50%] bg-white absolute bottom-[-5px] right-[-1px]"></div>
              </div>
              <div className="flex justify-between">
                <div className="w-full flex justify-start">
                  {selectedLoved > 0 && (
                    <img
                      className="animate-bounce cursor-pointer"
                      src={leftArrow}
                      alt="leftArrow"
                      onClick={() => handleSelectedIndex(selectedLoved - 1)}
                    />
                  )}
                </div>

                <div className="w-full text-white text-center text-2xl">
                  {selectedLoved + 1}/{lovedImg.length}
                </div>
                <div className="w-full flex justify-end">
                  {selectedLoved < lovedImg.length - 1 && (
                    <img
                      className="animate-bounce cursor-pointer"
                      src={rightArrow}
                      alt="leftArrow"
                      onClick={() => handleSelectedIndex(selectedLoved + 1)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center w-[100vw] h-[1117px] relative bg-black">
        <div className="absolute uppercase md:top-[16%] top-[8%] left-[5%] text-[#E4A0F7] font-monument-extended font-normal md:text-[40px] text-3xl md:leading-[48px]">
          CONTACT US
        </div>
        <div className="container mx-auto  md:p-28 p-4">
          <form onSubmit={postFormData} className="flex flex-col md:pt-8 pt-4 ">
            <div className="md:flex gap-24">
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                required
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                required
                type="number"
                name="number"
                placeholder="Phone Number"
                value={formData.number}
                onChange={handleChange}
              />
            </div>
            <div className="md:flex gap-24">
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              <input
                className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
                type="text"
                name="category"
                placeholder="Category"
                value={formData.category}
                onChange={handleChange}
              />
            </div>

            <textarea
              className="w-full my-6 p-6 rounded-[10px] placeholder:text-[#000] placeholder:text-2xl placeholder:font-[600]"
              rows={7}
              type="text"
              name="message"
              value={formData.message}
              placeholder="Message"
              onChange={handleChange}
            />
            <div className="flex md:justify-center justify-center items-center">
              <button
                type="submit"
                className="flex justify-center items-center button-gred text-white h-[60px] w-[195px] rounded-xl my-6 font-semibold text-base"
              >
                Send Message{" "}
                <img src={arrowRight} alt="arrowRight" className="ml-2" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
